import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Typography,
  Link,
  IconButton,
  Grid,
} from "@material-ui/core"
import InstagramIcon from "@material-ui/icons/Instagram"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import logo from "../assets/logo-5.png" // Adjust the import path as necessary
import { EmailOutlined } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(6),
    background: "#FCFCFC",
  },
  icon: {
    marginRight: "6px",
  },
  logo: {
    cursor: "pointer",
    width: 110,
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container justify="center" style={{ marginBottom: "12px" }}>
          <Grid item>
            <img src={logo} alt="Alchtec.com" className={classes.logo} />
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={1}
          style={{ marginBottom: "24px" }}
        >
          <Grid item>
            <Grid container alignItems="center">
              <EmailOutlined color="primary" className={classes.icon} />
              <Link color="inherit" href="https://instagram.com/alchtec">
                contacto@alchtec.com
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <WhatsAppIcon color="primary" className={classes.icon} />
              <Link color="inherit" href="https://wa.me/56927328806">
                +56 9 2732 8806
              </Link>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container alignItems="center">
              <InstagramIcon color="primary" className={classes.icon} />
              <Link color="inherit" href="https://instagram.com/alchtec">
                Instagram
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="body2" color="inherit" align="center">
          © 2024 Alchtec SpA. Todos los derechos reservados
        </Typography>
      </Container>
    </footer>
  )
}
