import { makeStyles } from "@material-ui/core/styles"

const useStyles = (fullScreen) => {
  return makeStyles((theme) => ({
    appContainer: {
      paddingTop: theme.spacing(4),
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    messagesContainer: {
      overflowY: "scroll",
      height: "59vh",
      [theme.breakpoints.down("md")]: {
        height: "55vh",
      },
    },

    textGrid: {
      overflowY: "auto",
      height: 550,
      padding: 12,
      [theme.breakpoints.down("sm")]: {
        height: "71vh",
      },
      [theme.breakpoints.up("md")]: {
        height: fullScreen && "85vh",
      },
    },

    paper2: {
      overflowY: "auto",
    },
    fileUploadContainer: {
      [theme.breakpoints.up("md")]: {
        padding: 12,
      },
    },
    progress: {
      marginLeft: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    header: {
      [theme.breakpoints.down("md")]: {
        height: "55vh",
        padding: 24,
      },
    },
  }))()
}

export { useStyles }
