// AuthorizedPageWrapper.js
import React from "react"
import useAuth from "./auth"
import { navigate } from "gatsby"
import LoadingBackdrop from "../components/LoadingBackdrop"

const AuthorizedPageWrapper = ({ children }) => {
  const { isAuthenticated, loading } = useAuth()

  if (loading) {
    return <LoadingBackdrop />
  }

  if (!isAuthenticated) {
    navigate("/login")
    return <div />
  }

  return <>{children}</>
}

export default AuthorizedPageWrapper
