import {
  ThemeProvider,
  Card,
  Paper,
  Avatar,
  Grid,
  makeStyles,
  Typography,
  Fab,
  useMediaQuery,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import theme from "./context/theme"
import Mage from "./assets/Mage.png"
import "./assets/fonts.css"
import { FaWandMagicSparkles } from "react-icons/fa6"
import "./AssistantWidget.css"

import Chat from "./components/Chat"
import soundFile from "./assets/notification.wav"
import useGetInitialConfig from "./components/Chat/useGetInitialConfig"
const useStyles = (fullScreen) => {
  return makeStyles((theme) => ({
    root: {
      width: "100vw",
      minHeight: "100vh",
      padding: 0,
      position: "relative", // Add relative position to allow absolute positioning inside
    },
    fabPaper: {
      position: "fixed",
      right: 90,
      bottom: 20,
      padding: 10,
      borderRadius: 50,
      backgroundColor: "#f5f5f5",
      [theme.breakpoints.down("md")]: {
        bottom: "3vh",
      },
    },
    fab: {
      position: "fixed",
      right: 20,
      bottom: 20,
      [theme.breakpoints.down("md")]: {
        bottom: "3vh",
      },
    },
    fabIcon: {
      color: "#fff",
      zIndex: 100,
    },
    agentCard: {
      position: "fixed", // Changed position to 'fixed'
      right: fullScreen ? 0 : 50,
      bottom: fullScreen ? 0 : 90,
      width: fullScreen ? "100vw" : 400,
      height: fullScreen ? "100vh" : 720,
      backgroundColor: "white",
      zIndex: 1000,
      borderRadius: fullScreen ? 0 : "16px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      [theme.breakpoints.down("md")]: {
        right: 0,
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        borderRadius: 0,
      },
    },
  }))()
}

const AssistantWidget = ({
  showAssistant = true,
  agentId,
  persistOpenChat,
  fullScreen,
}) => {
  console.log("fs", fullScreen)
  const classes = useStyles(fullScreen)
  const {
    data: initialConfig,
    loading: loadingInitialConfig,
    error: errorInitialConfig,
  } = useGetInitialConfig(agentId)
  const [showHelpMessage, setShowHelpMessage] = useState(false)
  const [initialSound, setInitialSound] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)
  const [openChat, setOpenChat] = useState(false)
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true)
    }

    document.addEventListener("click", handleUserInteraction)

    return () => {
      document.removeEventListener("click", handleUserInteraction)
    }
  }, [])

  useEffect(() => {
    if (userInteracted && !initialSound && !showHelpMessage && !openChat) {
      const timer = setTimeout(() => {
        console.log(initialSound, showHelpMessage)
        const audio = new Audio(soundFile)

        audio.play()
        setShowHelpMessage(true)
      }, 3000)

      return () => clearTimeout(timer) // Limpia el temporizador si el componente se desmonta o openChat cambia
    }
  }, [userInteracted, initialSound, showHelpMessage])

  return (
    <ThemeProvider theme={theme}>
      {!openChat && showAssistant && showHelpMessage && (
        <>
          <Paper elevation={5} className={classes.fabPaper}>
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <Avatar sizes="50px" src={Mage} />
              </Grid>
              <Grid item xs={10} style={{ paddingTop: 25 }}>
                <Typography>Hola! 👋 Necesitas ayuda?</Typography>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {!loadingInitialConfig && !errorInitialConfig && initialConfig && (
        <Fab
          color="primary"
          aria-label="chat"
          className={classes.fab}
          onClick={() => setOpenChat(!openChat)}
          style={{ zIndex: mdUp ? 51 : 49 }}
        >
          <FaWandMagicSparkles className={classes.fabIcon} />
        </Fab>
      )}
      {(openChat || persistOpenChat) && (
        <Card className={classes.agentCard}>
          <Chat
            persistOpenChat={persistOpenChat}
            fullScreen={fullScreen}
            setOpenChat={setOpenChat}
            initialConfig={initialConfig}
            loadingInitialConfig={loadingInitialConfig}
            agentId={agentId}
          />
        </Card>
      )}
    </ThemeProvider>
  )
}

export default AssistantWidget
