import { useEffect, useState } from "react"
import soundFile from "../../assets/noti2.wav"
import sendSoundFile from "../../assets/send.mp3"

const ENV = process.env.ENV

const URL_ENVS = {
  staging: "https://alchtec-nodejs.onrender.com",
  production: "https://alchtec-nodejs.onrender.com",
}

const URL_BASE = URL_ENVS[ENV] || "http://localhost:3001"

const useChat = (
  endRef,
  url = "/gpt/agent",
  refetchConversations = null,
  selectedMind,
  scrollToEnd = true,
  initialConfig,
  agentId
) => {
  const [input, setInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isWriting, setIsWriting] = useState(false)

  const [conversation, setConversation] = useState({
    memory: [],
  })
  const [conversationId, setConversationId] = useState(null)
  const errorMsg = "Ha ocurrido un error inesperado."

  useEffect(() => {
    if (initialConfig) {
      setConversation({
        memory: [initialConfig?.initialMessage],
      })
    }
  }, [initialConfig])

  const addInitialConversationMsg = (baseQuestion) => {
    const newConversation = conversation
    const newMemory = [...conversation.memory]

    newMemory.push({
      source: "user",
      text: baseQuestion || input,
    })

    newMemory.push({
      source: "system",
      text: "",
      senderType: "loading",
    })
    newConversation.memory = newMemory
    if (refetchConversations) {
      refetchConversations()
    }
    setConversation({ memory: newMemory })
    const audio = new Audio(sendSoundFile)
    audio.play()
  }
  const updateStreamConversation = (chunks, error) => {
    const newConversation = { ...conversation } // This creates a copy of conversation
    const newMemory = [...newConversation.memory] // This creates a copy of memory

    newMemory[newMemory.length - 1] = {
      source: "ai",
      text: chunks,
      senderType: "assistant",
      error,
    }
    newConversation.memory = newMemory
    setConversation(newConversation) // You can directly set the newConversation
  }

  const handleRefresh = () => {
    setConversation({
      memory: Array.from([initialConfig?.initialMessage]),
    })
    setConversationId(null)
  }

  const processStream = async (response) => {
    const reader = response.body.getReader()
    let chunks = ""
    let readTimeout
    while (true) {
      const { done, value } = await reader.read()
      const chunk = new TextDecoder("utf-8").decode(value)
      if (done) {
        setIsWriting(false)
        scrollToEnd &&
          endRef.current.scrollIntoView({ behavior: "smooth", block: "end" }) // Scroll to the bottom

        break
      }
      clearTimeout(readTimeout)

      if (chunk.includes("conversationId:")) {
        const conversationId = chunk.split(":")
        console.log("conversationid", conversationId)
        setConversationId(conversationId[1])
        if (refetchConversations) {
          await refetchConversations()
        }
      } else {
        chunks += chunk
        updateStreamConversation(chunks)
        scrollToEnd &&
          endRef.current.scrollIntoView({ behavior: "smooth", block: "end" }) // Scroll to the bottom
      }
    }

    const audio = new Audio(soundFile)
    audio.play()
  }

  const handleSubmit = async (baseQuestion, isOptionClick) => {
    const token = localStorage.getItem("token")
    addInitialConversationMsg(baseQuestion)
    setLoading(true)
    setIsWriting(true)

    try {
      setInput("")
      const withMindUrl = URL_BASE + url + `/${selectedMind}`
      const normalUrl = URL_BASE + url
      const selectedUrl = selectedMind ? withMindUrl : normalUrl

      const response = await fetch(selectedUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          input: baseQuestion || input,
          conversationId: conversationId,
          isOptionClick,
          agentId,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      if (isOptionClick) {
        const toJsonResponse = await response.json()

        if (toJsonResponse?.agentResponse) {
          console.log(toJsonResponse?.agentResponse)
          const newConversation = { ...conversation } // This creates a copy of conversation
          const newMemory = [...newConversation.memory] // This creates a copy of memory

          newMemory[newMemory.length - 1] = toJsonResponse?.agentResponse
          newConversation.memory = newMemory
          setConversation(newConversation) // You can directly set the newConversation
          setConversationId(toJsonResponse?.conversationId)
          const audio = new Audio(soundFile)
          audio.play()
          endRef.current.scrollIntoView({ behavior: "smooth", block: "end" }) // Scroll to the bottom
        }
      } else {
        endRef.current.scrollIntoView({ behavior: "smooth", block: "end" }) // Scroll to the bottom
        await processStream(response)
      }
    } catch (error) {
      console.log(error)

      updateStreamConversation(
        "Ha ocurrido un error inesperado, intenta más tarde.",
        true
      )
    } finally {
      setIsWriting(false)
      setLoading(false)
    }
  }

  const handleMemoryUpload = (conversation) => {
    console.log(conversation)
    setConversationId(conversation?._id)
    setConversation(conversation)
  }

  return {
    input,
    setInput,
    loading,
    error,
    conversation,
    conversationId,
    setConversationId,
    handleMemoryUpload,
    handleSubmit,
    isWriting,
    errorMsg,
    setError,
    handleRefresh,
  }
}

export default useChat
