import React, { useState } from "react"
import { IconButton, makeStyles } from "@material-ui/core"
import { FaPaperPlane } from "react-icons/fa6"

const useStyles2 = makeStyles((theme) => ({
  customInputContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: "#fff",
    borderRadius: 4,
    padding: 8,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  customInput: {
    flex: 1,
    border: "none",
    outline: "none",
    padding: 8,
    fontSize: 16,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: 20,
    marginLeft: 3,
    padding: 6,
  },
  iconButtonDisabled: {
    cursor: "not-allowed",
    opacity: 0.5,
  },
  glow: {
    animation: "$glow 1s infinite",
  },
  "@keyframes glow": {
    "0%": {
      boxShadow: "0 0 5px #007BFF",
    },
    "50%": {
      boxShadow: "0 0 10px #007BFF",
    },
    "100%": {
      boxShadow: "0 0 5px #007BFF",
    },
  },
}))

const AgentInput = ({
  value,
  onChange,
  onKeyDown,
  disabled,
  className,
  handleSubmit,
}) => {
  const classes = useStyles2()
  const [isSendingAudio, setSendingAudio] = useState(false)

  const handleMicrophoneClick = () => {
    setSendingAudio(!isSendingAudio)
  }
  const onStop = (recordedBlob) => {
    console.log("Recorded Blob:", recordedBlob)
    // Aquí puedes manejar el blob de audio grabado
  }

  return (
    <div className={`${classes.customInputContainer} ${className}`}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className={classes.customInput}
        placeholder="Escribe tu consulta"
      />
      <div className={classes.iconContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={handleSubmit}
          disabled={disabled}
        >
          <FaPaperPlane style={{ color: "#000" }} />
        </IconButton>
      </div>
    </div>
  )
}

export default AgentInput
