import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  MenuList,
  MenuItem,
} from "@material-ui/core"
import { deepPurple, deepOrange } from "@material-ui/core/colors"
import Mage from "../../assets/Mage.png"
import ReactMarkdown from "react-markdown"
import { ThreeDots } from "react-loader-spinner"

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 12,
    alignItems: "flex-start", // Asegura que los elementos hijos se alineen al inicio
    background: "inherit",
    width: "100%",
    marginBottom: 20,
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  card: {
    display: "flex",
    maxWidth: "100%",
    borderRadius: 12,
    alignItems: "center",
    background: "inherit",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  menuCard: {
    display: "flex",
    maxWidth: "100%",
    borderRadius: 12,
    alignItems: "center",
    background: "inherit",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  menuItem: {
    backgroundColor: "#f8f8f8",
    overflowY: "auto",
    "&:hover": {
      backgroundColor: "#546270",
      color: "#fff",
    },
  },
  avatar: {
    backgroundColor: (props) =>
      props.senderType === "user" ? deepPurple[500] : deepOrange[500],
    marginRight: (props) =>
      props.senderType === "user" ? theme.spacing(1) : 0,
    marginLeft: (props) => (props.senderType === "ai" ? theme.spacing(1) : 0),
  },
  content: {
    flex: 1,
  },
  message: {
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    textAlign: "justify",

    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.up("md")]: {},
  },
}))

const ChatMessage = ({
  message,
  senderType,
  systemMessage,
  error,
  addon,
  options,
  onChipClick,
  hasNextMessage,
  paperRef,
  fullScreen,
  refreshed,
  setRefreshed,
}) => {
  const classes = useStyles({ senderType })
  const [clickedOptions, setClickedOptions] = useState(false)

  useEffect(() => {
    if (refreshed) {
      setClickedOptions(false)
      setRefreshed(false)
    }
  }, [refreshed])

  // Default message rendering
  return (
    <>
      <Grid container className={classes.messageContainer} key={message}>
        {(senderType === "ai" || senderType === "loading") &&
          message !== undefined && (
          <Grid item xs={2} md={fullScreen ? 1 : 2}>
            <Avatar
              style={{ width: 40, height: 40, marginTop: 20 }}
              src={Mage}
            />
          </Grid>
        )}

        {addon !== "multiSelect" && <div ref={paperRef} />}

        {message !== undefined && (
          <Grid item container xs={10}>
            <Card
              elevation={2}
              className={classes.card}
              style={{
                borderColor: error && "#ffdbd0",
                borderStyle: error && "solid",
                background: senderType === "user" ? "#546270" : "#f8f8f8",
              }}
            >
              <CardContent className={classes.content} justify="center">
                {senderType === "loading" ? (
                  <Grid container justify="center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#3858ff"
                      ariaLabel="three-dots-loading"
                      visible={true}
                    />
                  </Grid>
                ) : (
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: senderType === "user" && "#fff",
                    }}
                    variant="body2"
                    component="p"
                  >
                    <ReactMarkdown>{message}</ReactMarkdown>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      {addon === "multiSelect" && !clickedOptions && !hasNextMessage && (
        <Grid container justify="flex-end">
          <div ref={paperRef} />
          <Grid item xs={12} md={fullScreen ? 8 : 12}>
            <Card
              elevation={2}
              className={classes.card}
              style={{
                width: "100%",
                padding: 0,
                background: "#f8f8f8",
                overflowY: "auto",
              }}
            >
              <MenuList style={{ padding: 0, width: "100%" }}>
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    className={classes.menuItem}
                    onClick={() => {
                      onChipClick(option)
                      setClickedOptions(true)
                    }}
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  >
                    <Typography style={{ padding: 12 }}>{option}</Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ChatMessage
