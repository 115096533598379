import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3858FF", // Your primary color
    },
    secondary: {
      main: "#B14FFF", // Your secondary color
    },
    tertiary: {
      main: "#dc343a", // Your tertiary color
      light: "#e66b6f", // A lighter shade for hover and active states
    },
    background: {},

    gradient: {
      main: "linear-gradient(45deg, #008496, #00C0DC)",
    },
    dark: {
      main: "#366157",
      secondary: "#18153d",
      darker: "#010035",
    },
  },
  typography: {
    fontFamily: "Gilroy Regular",
    h1: {
      fontSize: "2.5rem",
      fontFamily: "Gilroy Bold",
      fontWeight: 400,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0.01em",
      fontFamily: "Gilroy Regular",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: "0.01em",
      fontFamily: "Gilroy Bold",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.01em",
      fontFamily: "Gilroy Bold",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.01em",
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 500,
      lineHeight: 1.7,
      letterSpacing: "0.01em",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 25,
      },
    },
    MuiPaper: {
      root: {},
    },
  },
})

export default theme
