import React, { useState, useEffect, useRef } from "react"
import {
  Paper,
  Snackbar,
  Grid,
  Typography,
  IconButton,
  Container,
} from "@material-ui/core"
import { useStyles } from "./styles"
import ChatMessage from "./ChatMessage"
import Alert from "@material-ui/lab/Alert"
import useChat from "./useChat"

import { Close, Refresh } from "@material-ui/icons"
import AgentInput from "./AgentInput"
import { FaWandMagicSparkles } from "react-icons/fa6"
import ContentLoader from "react-content-loader"

const Chat = ({
  setOpenChat,
  initialConfig,
  agentId,
  persistOpenChat,
  fullScreen,
  loadingInitialConfig,
}) => {
  const classes = useStyles(fullScreen)
  const paperRef = useRef()
  const endRef = useRef()
  const {
    input,
    setInput,
    loading,
    setError,
    conversation,
    handleRefresh,
    handleSubmit,
    isWriting,
  } = useChat(endRef, "/gpt/agent", null, false, false, initialConfig, agentId)
  const [refreshed, setRefreshed] = useState(false)
  const [showClipboardNotification, setShowClipboardNotification] =
    useState(false)
  const [isFeedbackModalOpen, setShowFeedbackModal] = useState(false)
  const handleInputChange = (event) => {
    setInput(event.target.value)
  }

  useEffect(() => {
    endRef?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }, [conversation])

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setError(false)
    setShowClipboardNotification(false)
  }

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false)
  }

  const handleOpenPositiveFeedbackModal = () => {
    setShowFeedbackModal(true)
    if (!feedbackId) {
      createFeedback(true)
    }
  }

  const handleOpenNegativeFeedbackModal = () => {
    setShowFeedbackModal(true)
    if (!feedbackId) {
      createFeedback(false)
    }
  }

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Paper
            ref={paperRef}
            elevation={0}
            className={classes.paper2}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container justify="flex-start">
              <Grid container>
                <Grid
                  container
                  justify={fullScreen && "center"}
                  alignItems={fullScreen && "center"}
                  alignContent="center"
                  style={{ background: "#3858ff", paddingBottom: 12 }}
                >
                  <Container maxWidth="md">
                    <Grid container justify="space-between">
                      {!persistOpenChat && (
                        <Grid
                          item
                          lg={fullScreen ? 1 : 2}
                          style={{ paddingTop: 12 }}
                        >
                          <IconButton onClick={() => setOpenChat(false)}>
                            <Close style={{ color: "#fff" }} />
                          </IconButton>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={9}
                        md={10}
                        lg={9}
                        style={{ paddingTop: 16 }}
                      >
                        <Typography
                          style={{
                            fontWeight: 900,
                            color: "#fff",
                            fontSize: 24,
                          }}
                        >
                          Asistente Alchtec 🧙‍♂️
                        </Typography>
                      </Grid>

                      {persistOpenChat && (
                        <Grid item style={{ paddingTop: 12 }}>
                          <IconButton
                            onClick={() => {
                              handleRefresh()
                              setRefreshed(true)
                            }}
                          >
                            <Refresh style={{ color: "#fff" }} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Container>
                </Grid>
                {loadingInitialConfig && (
                  <div className={classes.textGrid}>
                    <Grid
                      container
                      justify="center"
                      style={{
                        height: "100vh",
                        width: "100vw",
                      }}
                    >
                      <Grid item>
                        <ContentLoader
                          speed={2}
                          width={600}
                          height={180}
                          viewBox="0 0 600 180"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="3"
                            ry="3"
                            width="600"
                            height="180"
                          />
                        </ContentLoader>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
            {fullScreen && (
              <Container maxWidth="md">
                <div className={classes.textGrid}>
                  <Grid container style={{ overflowY: "auto", padding: 12 }}>
                    <Grid container style={{ height: "fit-content" }}>
                      {conversation?.memory?.map(
                        (
                          {
                            text,
                            source,
                            senderType,
                            systemMessage,
                            error,
                            addon,
                            options,
                          },
                          index
                        ) => (
                          <ChatMessage
                            key={text}
                            message={text}
                            addon={addon}
                            options={options}
                            paperRef={endRef}
                            sender={source === "ai" ? "Asistente" : "Tú"}
                            senderType={
                              senderType === "loading" ? senderType : source
                            }
                            systemMessage={systemMessage}
                            isInitialMessage={index === 0}
                            isWritingMessage={isWriting}
                            setShowClipboardNotification={
                              setShowClipboardNotification
                            }
                            handleOpenPositiveFeedbackModal={
                              handleOpenPositiveFeedbackModal
                            }
                            handleOpenNegativeFeedbackModal={
                              handleOpenNegativeFeedbackModal
                            }
                            hasNextMessage={
                              index < conversation.memory.length - 1
                            }
                            error={error}
                            onChipClick={(option) => {
                              handleSubmit(option, true)
                            }}
                            fullScreen={fullScreen}
                            refreshed={refreshed}
                            setRefreshed={setRefreshed}
                          />
                        )
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Container>
            )}
            {!fullScreen && (
              <div className={classes.textGrid}>
                <Grid container style={{ overflowY: "auto", padding: 12 }}>
                  <Grid container style={{ height: "fit-content" }}>
                    {conversation?.memory?.map(
                      (
                        {
                          text,
                          source,
                          senderType,
                          systemMessage,
                          error,
                          addon,
                          options,
                        },
                        index
                      ) => (
                        <ChatMessage
                          key={text}
                          message={text}
                          addon={addon}
                          options={options}
                          paperRef={endRef}
                          sender={source === "ai" ? "Asistente" : "Tú"}
                          senderType={
                            senderType === "loading" ? senderType : source
                          }
                          systemMessage={systemMessage}
                          isInitialMessage={index === 0}
                          isWritingMessage={isWriting}
                          setShowClipboardNotification={
                            setShowClipboardNotification
                          }
                          handleOpenPositiveFeedbackModal={
                            handleOpenPositiveFeedbackModal
                          }
                          handleOpenNegativeFeedbackModal={
                            handleOpenNegativeFeedbackModal
                          }
                          hasNextMessage={
                            index < conversation.memory.length - 1
                          }
                          error={error}
                          onChipClick={(option) => {
                            handleSubmit(option, true)
                          }}
                          fullScreen={fullScreen}
                          refreshed={refreshed}
                          setRefreshed={setRefreshed}
                        />
                      )
                    )}
                  </Grid>
                  <div ref={paperRef} />
                </Grid>
              </div>
            )}
            <Container maxWidth="md">
              <Grid container style={{ paddingLeft: 24, paddingRight: 24 }}>
                <Grid item xs={12}>
                  <AgentInput
                    value={input}
                    onChange={handleInputChange}
                    handleSubmit={() => {
                      event.preventDefault() // Para evitar que se agregue una nueva línea
                      handleSubmit()
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && !event.shiftKey) {
                        event.preventDefault() // Para evitar que se agregue una nueva línea
                        handleSubmit()
                      }
                    }}
                    disabled={loading || isWriting}
                    className={classes.textField}
                  />
                </Grid>
                <Grid container justify="center">
                  <Typography
                    variant="caption"
                    style={{ textAlign: "center", color: "#b4b4b4" }}
                  >
                    Powered by Alchtec <FaWandMagicSparkles />
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={showClipboardNotification}
        autoHideDuration={2000}
        onClose={handleCloseNotification}
      >
        <Alert> Mensaje copiado al cortapapeles.</Alert>
      </Snackbar>
    </div>
  )
}

export default Chat
