import React from "react"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}))

const LoadingBackdrop = ({ loading }) => {
  const classes = useStyles()

  return (
    <div className={classes.backdrop} open={loading}>
      <h1>Alchtec.com</h1>
      <CircularProgress color="inherit" />
    </div>
  )
}

export default LoadingBackdrop
